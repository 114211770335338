import React from "react"
import { useOverrides, Override } from "@quarkly/components"
import { Image, Text, LinkBox, Box, Link, Section } from "@quarkly/widgets"
import QuarklycommunityKitMenu from "./QuarklycommunityKitMenu"
import QuarklycommunityKitMobileSidePanel from "./QuarklycommunityKitMobileSidePanel"
import { FiMenu } from "react-icons/fi"
import { AiOutlineMenu } from "react-icons/ai"
const defaultProps = {
  "sm-padding": "8px 0 8px 0",
  "quarkly-title": "Header-6",
  padding: "24px 0px 24px 0px",
}
const overrides = {
  quarklycommunityKitMobileSidePanel: {
    kind: "QuarklycommunityKitMobileSidePanel",
    props: {
      menuPosition: "full",
      breakpoint: "lg",
      width: "40%",
      "sm-width": "50%",
      "md-width": "50%",
      "lg-width": "40%",
      "lg-justify-content": "flex-start",
      "md-justify-content": "flex-end",
    },
  },
  quarklycommunityKitMobileSidePanelOverride: {
    kind: "Override",
    props: {
      slot: "Children",
      "md-display": "flex",
    },
  },
  quarklycommunityKitMobileSidePanelOverride1: {
    kind: "Override",
    props: {
      slot: "Content",
      padding: "0px 0px 0px 0px",
      background: "rgba(255, 255, 255, 0)",
      "lg-background": "#ffffff",
      "lg-margin": "0px 0px 0px 0px",
    },
  },
  quarklycommunityKitMobileSidePanelOverride2: {
    kind: "Override",
    props: {
      slot: "Button Text",
      font:
        "normal 600 16px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
      "text-transform": "uppercase",
      "letter-spacing": "1px",
      "sm-font":
        "normal 600 14px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
      "sm-margin": "0px 2px 0px 0px",
      "lg-margin": "0px 0px 0px 0px",
      display: "none",
    },
  },
  quarklycommunityKitMobileSidePanelOverride3: {
    kind: "Override",
    props: {
      slot: "Button Icon :closed",
      category: "fi",
      icon: FiMenu,
      size: "32px",
      padding: "5px 7px 5px 7px",
      "border-radius": "50px",
    },
  },
  quarklycommunityKitMobileSidePanelOverride4: {
    kind: "Override",
    props: {
      slot: "Button Icon",
      width: "28px",
      height: "28px",
      category: "ai",
      icon: AiOutlineMenu,
      color: "--dark",
      size: "24px",
      "lg-width": "32px",
      "lg-height": "32px",
    },
  },
  quarklycommunityKitMobileSidePanelOverride5: {
    kind: "Override",
    props: {
      slot: "Cross",
      "lg-width": "32px",
      "lg-height": "32px",
      size: "32px",
      top: "24px",
      right: "24px",
    },
  },
  box: {
    kind: "Box",
    props: {
      "align-items": "center",
      "lg-justify-content": "center",
      "lg-align-items": "flex-start",
      "justify-content": "flex-start",
      display: "flex",
      "lg-flex-direction": "column",
      "lg-margin": "0px auto 0px auto",
      "lg-min-width": "300px",
      "lg-max-width": "1280px",
      "lg-width": "90%",
      "lg-padding": "24px 0px 48px 0px",
    },
  },
  box1: {
    kind: "Box",
    props: {
      display: "none",
      "lg-width": "100%",
      "lg-margin": "0px 0px 24px 0px",
      "lg-display": "flex",
      "lg-padding": "12px 0px 12px 0px",
    },
  },
  linkBox: {
    kind: "LinkBox",
    props: {
      "flex-direction": "row",
      href: "/index",
      display: "flex",
      "grid-gap": "12px",
    },
  },
  image: {
    kind: "Image",
    props: {
      src: "https://in-blasetump.com/img/6425534.png",
      display: "block",
      width: "36px",
      height: "36px",
    },
  },
  text: {
    kind: "Text",
    props: {
      margin: "0",
      "md-margin": "0px 0 0px 0",
      "text-align": "left",
      font: "--lead",
      "sm-margin": "0px 0 0px 0",
      display: "block",
      "lg-font": "--headline3",
      children: "In-blase Food Delivery",
    },
  },
  quarklycommunityKitMenu: {
    kind: "QuarklycommunityKitMenu",
    props: {
      display: "flex",
      filterMode: "exclude",
      "grid-gap": "4px",
      "lg-flex-direction": "column",
      "lg-padding": "6px 0px 6px 0px",
      "lg-margin": "0px 0px 24px 0px",
      "align-items": "center",
      "flex-wrap": "wrap",
      "overflow-x": "visible",
      "overflow-y": "visible",
      "lg-align-items": "flex-start",
    },
  },
  quarklycommunityKitMenuOverride: {
    kind: "Override",
    props: {
      slot: "link",
      color: "--darkL2",
      "hover-color": "--primary",
      font: "--base",
      "text-decoration-line": "initial",
      transition: "color 0.1s ease 0s",
      "lg-font": "--lead",
    },
  },
  quarklycommunityKitMenuOverride1: {
    kind: "Override",
    props: {
      slot: "item-active",
      "border-width": "0 0 1px 0",
      "border-style": "solid",
      "border-color": "--color-darkL2",
      "lg-border-width": "0 0 0 2px",
    },
  },
  quarklycommunityKitMenuOverride2: {
    kind: "Override",
    props: {
      slot: "item",
      padding: "6px 6px 6px 6px",
      "lg-padding": "6px 12px 6px 12px",
    },
  },
  quarklycommunityKitMenuOverride3: {
    kind: "Override",
    props: {
      slot: "link-active",
      cursor: "default",
      color: "--darkL2",
      "hover-color": "--darkL2",
    },
  },
  quarklycommunityKitMenuOverride4: {
    kind: "Override",
    props: {
      slot: "link-about",
    },
  },
  box2: {
    kind: "Box",
    props: {
      display: "none",
      padding: "12px 0",
      "justify-content": "flex-end",
      "align-items": "flex-start",
      "flex-direction": "row",
      width: "40%",
      "lg-width": "100%",
      "md-justify-content": "flex-start",
      "lg-justify-content": "flex-start",
      "lg-padding": "0px 0 12px 0",
      "lg-flex-direction": "column",
      "lg-margin": "0px 0px 24px 0px",
      "lg-display": "flex",
      "lg-grid-gap": "24px",
    },
  },
  link: {
    kind: "Link",
    props: {
      font: "--base",
      "text-decoration-line": "initial",
      margin: "0px 0px 0px 16px",
      background: "--color-secondary",
      "border-radius": "8px",
      href: "/contacts",
      color: "#ffffff",
      padding: "6px 12px 7px 12px",
      "lg-padding": "12px 16px 12px 16px",
      "lg-font": "--lead",
      "lg-margin": "0px 0px 0px 0px",
      children: "Contact us",
    },
  },
  box3: {
    kind: "Box",
    props: {
      width: "25%",
      display: "none",
      "lg-width": "100%",
      "lg-flex-direction": "column",
      "lg-align-items": "flex-start",
      "lg-display": "flex",
      "justify-content": "space-around",
      "align-items": "center",
      "flex-wrap": "wrap",
      "lg-margin": "24px 0px 0px 0px",
    },
  },
  box4: {
    kind: "Box",
    props: {
      display: "flex",
      padding: "12px 0",
      "justify-content": "center",
      "align-items": "flex-start",
      "flex-direction": "row",
      width: "20%",
      "sm-width": "50%",
      "sm-align-items": "center",
      "sm-flex-direction": "row",
      "sm-justify-content": "flex-start",
      "md-width": "50%",
      "lg-width": "20%",
      "md-justify-content": "flex-start",
      "md-order": "-1",
    },
  },
  linkBox1: {
    kind: "LinkBox",
    props: {
      "flex-direction": "row",
      href: "/index",
      display: "flex",
      "grid-gap": "12px",
    },
  },
  image1: {
    kind: "Image",
    props: {
      src: "https://in-blasetump.com/img/6425534.png",
      display: "block",
      width: "36px",
      height: "36px",
    },
  },
  text1: {
    kind: "Text",
    props: {
      margin: "0",
      "md-margin": "0px 0 0px 0",
      "text-align": "left",
      font: "--lead",
      "sm-margin": "0px 0 0px 0",
      display: "block",
      children: "In-blase Food Delivery",
    },
  },
  box5: {
    kind: "Box",
    props: {
      display: "flex",
      padding: "12px 0",
      "justify-content": "flex-end",
      "align-items": "flex-start",
      "flex-direction": "row",
      width: "40%",
      "sm-width": "50%",
      "sm-align-items": "center",
      "sm-flex-direction": "row",
      "sm-justify-content": "flex-start",
      "md-width": "50%",
      "lg-width": "40%",
      "md-justify-content": "flex-start",
      "md-display": "none",
    },
  },
  link1: {
    kind: "Link",
    props: {
      font: "--base",
      "text-decoration-line": "initial",
      margin: "0px 0px 0px 16px",
      background: "--color-primary",
      "border-radius": "8px",
      "md-margin": "0px 0px 0px 14px",
      href: "/contacts",
      color: "#ffffff",
      padding: "6px 12px 7px 12px",
      "md-order": "1",
      children: "Contact us",
    },
  },
}

const Header = props => {
  const { override, children, rest } = useOverrides(
    props,
    overrides,
    defaultProps
  )
  return (
    <Section {...rest}>
      <Override
        slot="SectionContent"
        flex-direction="row"
        justify-content="space-between"
        max-width="100%"
        width="100%"
        padding="0px 24px 0px 24px"
      />
      <QuarklycommunityKitMobileSidePanel
        {...override("quarklycommunityKitMobileSidePanel")}
      >
        <Override {...override("quarklycommunityKitMobileSidePanelOverride")} />
        <Override
          {...override("quarklycommunityKitMobileSidePanelOverride1")}
        />
        <Override
          {...override("quarklycommunityKitMobileSidePanelOverride2")}
        />
        <Override
          {...override("quarklycommunityKitMobileSidePanelOverride3")}
        />
        <Override
          {...override("quarklycommunityKitMobileSidePanelOverride4")}
        />
        <Override
          {...override("quarklycommunityKitMobileSidePanelOverride5")}
        />
        <Box {...override("box")}>
          <Box {...override("box1")}>
            <LinkBox {...override("linkBox")}>
              <Image {...override("image")} />
              <Text {...override("text")} />
            </LinkBox>
          </Box>
          <QuarklycommunityKitMenu {...override("quarklycommunityKitMenu")}>
            <Override {...override("quarklycommunityKitMenuOverride")} />
            <Override {...override("quarklycommunityKitMenuOverride1")} />
            <Override {...override("quarklycommunityKitMenuOverride2")} />
            <Override {...override("quarklycommunityKitMenuOverride3")} />
            <Override {...override("quarklycommunityKitMenuOverride4")} />
          </QuarklycommunityKitMenu>
          <Box {...override("box2")}>
            <Link {...override("link")} />
          </Box>
          <Box {...override("box3")} />
        </Box>
      </QuarklycommunityKitMobileSidePanel>
      <Box {...override("box4")}>
        <LinkBox {...override("linkBox1")}>
          <Image {...override("image1")} />
          <Text {...override("text1")} />
        </LinkBox>
      </Box>
      <Box {...override("box5")}>
        <Link {...override("link1")} />
      </Box>
      {children}
    </Section>
  )
}

Object.assign(Header, { ...Section, defaultProps, overrides })
export default Header
