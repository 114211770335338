import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Image, Box, Button, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "80px 0 90px 0",
	"background": "--color-primary",
	"quarkly-title": "Cards-4"
};
const overrides = {
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 50px 0px",
			"color": "--light",
			"font": "normal 400 52px/1.2 --fontFamily-sans",
			"children": "Services"
		}
	},
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "grid",
			"grid-template-columns": "repeat(4, 1fr)",
			"grid-gap": "36px 34px",
			"margin": "0px 0px 50px 0px",
			"lg-grid-template-columns": "repeat(2, 1fr)",
			"sm-grid-template-columns": "1fr"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "center"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"src": "https://in-blasetump.com/img/5.jpg",
			"display": "block",
			"margin": "0px 0px 25px 0px",
			"width": "100%",
			"border-radius": "25px",
			"object-fit": "cover",
			"height": "350px"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 10px 0px",
			"font": "--headline3",
			"color": "--light",
			"text-align": "center",
			"children": "Curated Weekly Menus"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0 0px",
			"color": "#ffe8e1",
			"font": "--lead",
			"text-align": "center",
			"children": "Experience new flavors every week with our specially curated menus, featuring unique dishes from different cultures and cuisines."
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "center"
		}
	},
	"image1": {
		"kind": "Image",
		"props": {
			"src": "https://in-blasetump.com/img/6.jpg",
			"display": "block",
			"margin": "0px 0px 25px 0px",
			"width": "100%",
			"border-radius": "25px",
			"height": "350px",
			"object-fit": "cover"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 10px 0px",
			"font": "--headline3",
			"color": "--light",
			"text-align": "center",
			"children": "Corporate and Group Orders"
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0 0px",
			"color": "#ffe8e1",
			"font": "--lead",
			"text-align": "center",
			"children": "Perfect for office lunches or group gatherings, we provide hassle-free ordering for larger groups, complete with individual packaging and diverse menu options."
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "center"
		}
	},
	"image2": {
		"kind": "Image",
		"props": {
			"src": "https://in-blasetump.com/img/7.jpg",
			"display": "block",
			"margin": "0px 0px 25px 0px",
			"width": "100%",
			"border-radius": "25px",
			"height": "350px",
			"object-fit": "cover"
		}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 10px 0px",
			"font": "--headline3",
			"color": "--light",
			"text-align": "center",
			"children": "Eco-Friendly Packaging"
		}
	},
	"text6": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0 0px",
			"color": "#ffe8e1",
			"font": "--lead",
			"text-align": "center",
			"children": "Committed to sustainability, we utilize eco-friendly packaging for all our deliveries, reducing environmental impact."
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "center"
		}
	},
	"image3": {
		"kind": "Image",
		"props": {
			"src": "https://in-blasetump.com/img/8.jpg",
			"display": "block",
			"margin": "0px 0px 25px 0px",
			"width": "100%",
			"border-radius": "25px",
			"height": "350px",
			"object-fit": "cover"
		}
	},
	"text7": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 10px 0px",
			"font": "--headline3",
			"color": "--light",
			"text-align": "center",
			"children": "Personalized Recommendations"
		}
	},
	"text8": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0 0px",
			"color": "#ffe8e1",
			"font": "--lead",
			"text-align": "center",
			"children": "Not sure what to order? Our system offers personalized recommendations based on your previous orders and preferences."
		}
	},
	"button": {
		"kind": "Button",
		"props": {
			"font": "normal 300 24px/1.5 --fontFamily-sansTrebuchet",
			"padding": "15px 44px 15px 44px",
			"background": "--color-secondary",
			"border-radius": "15px",
			"color": "white",
			"href": "/contacts",
			"type": "link",
			"text-decoration-line": "initial"
		}
	}
};

const Services = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" align-items="center" />
		<Text {...override("text")} />
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Image {...override("image")} />
				<Text {...override("text1")} />
				<Text {...override("text2")} />
			</Box>
			<Box {...override("box2")}>
				<Image {...override("image1")} />
				<Text {...override("text3")} />
				<Text {...override("text4")} />
			</Box>
			<Box {...override("box3")}>
				<Image {...override("image2")} />
				<Text {...override("text5")} />
				<Text {...override("text6")} />
			</Box>
			<Box {...override("box4")}>
				<Image {...override("image3")} />
				<Text {...override("text7")} />
				<Text {...override("text8")} />
			</Box>
		</Box>
		<Button {...override("button")}>
			Contact Us
		</Button>
		{children}
	</Section>;
};

Object.assign(Services, { ...Section,
	defaultProps,
	overrides
});
export default Services;